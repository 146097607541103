import { useLayoutEffect } from "react";

/** Constants */
import { LS_THEME, THEME } from "../constants";

/** Hooks */
import useLocalStorage from "./useLocalStorage";

/** Utils */
import logger from "../utils/logger";

/** Types */
import type { OneOfValues } from "@/types/util";

function useThemeState(initialValue: OneOfValues<typeof THEME>) {
  const [value, setValue] = useLocalStorage(LS_THEME, initialValue);
  const isDarkMode = value === THEME.DARK;

  /* ------------- App: Global Theme ------------ */
  useLayoutEffect(() => {
    const theme = isDarkMode ? THEME.DARK : THEME.LIGHT;

    /** Set data attribute on body element */
    document.body.dataset["theme"] = theme;

    /** Get cache busting css href from <template> */
    const template = document.querySelector(`.js-stylesheet-${theme}`);
    const href = template?.getAttribute("href");

    if (href) {
      const stylesheet = document.querySelector(".js-stylesheet");
      stylesheet!.setAttribute("href", href);
    } else {
      logger.error(`stylesheet for ${theme} theme not found.`);
    }
  }, [isDarkMode]);

  return [value, setValue];
}

export default useThemeState;
